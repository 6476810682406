export enum PrefillStatusEnum {
  Prefilled = 'company_found', // used data from FindMe service
  NotPrefilled = 'company_not_found', // unable to use data from FindMe (error, company already exist, ...)
  Skipped = 'skipped', // skipped usage of FindMe service
}

export const headerTranslationKeys = {
  [PrefillStatusEnum.Prefilled]: {
    header: {
      key: 'company_info.multiheader.company_found.page_header',
      defaultValue: 'We found you',
    },
    subHeader: {
      key: 'company_info.multiheader.company_found.subheader',
      defaultValue: 'Can you confirm this info is ',
    },
  },
  [PrefillStatusEnum.NotPrefilled]: {
    header: {
      key: 'company_info.multiheader.company_not_found.page_header',
      defaultValue: "We didn't found you",
    },
    subHeader: {
      key: 'company_info.multiheader.company_not_found.subheader',
      defaultValue: 'Can you complete the information?',
    },
  },
  [PrefillStatusEnum.Skipped]: {
    header: {
      key: 'company_info.multiheader.skipped.page_header',
      defaultValue: 'We made affiliation super easy!',
    },
    subHeader: {
      key: 'company_info.multiheader.skipped.subheader',
      defaultValue: "Let's start with your company information",
    },
  },
};
