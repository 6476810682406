import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { PreviewFileProps } from './types';

const PreviewImgFile = ({ file, width = 200 }: PreviewFileProps) => {
  const [preview, setPreview] = useState<string | null>(null);

  useEffect(() => {
    if (!file) return;

    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  // eslint-disable-next-line @next/next/no-img-element
  return preview ? (
    <Box p={4} alignSelf="flex-start" background="semantic.background.info">
      <Box as="img" src={preview} alt={file.name} width={width} />
    </Box>
  ) : null;
};

export default PreviewImgFile;
