import { Box, Center, SpaceProps, useImage } from '@chakra-ui/react';
import { CampaignType } from 'types/campaign';

interface CampaignLogoProps extends SpaceProps {
  campaign: CampaignType;
  size?: 'sm' | 'md';
}

const mtDefault = { base: 0, md: -6 };

const CampaignLogo = ({
  campaign: { isActive, logoUrl, name },
  mb = 6,
  mt = mtDefault,
  ...rest
}: CampaignLogoProps) => {
  const isLoaded = useImage({ src: isActive ? logoUrl : undefined }) === 'loaded';

  if (isLoaded) {
    return (
      <Center mt={mt} mb={mb} {...rest}>
        <Box as="img" alt={name} src={logoUrl} width={120} height={120} objectFit="scale-down" />
      </Center>
    );
  }
  return null;
};

export default CampaignLogo;
