import { Base64 } from 'js-base64';

export const encodeValue = value => Base64.encode(JSON.stringify(value));
export const decodeValue = encodedValue => JSON.parse(Base64.decode(encodedValue));

export const setLocalStorage = (name, value) => {
  // eslint-disable-next-line no-unused-expressions
  'undefined' !== typeof window && window.localStorage?.setItem(name, encodeValue(value));
};

export const getLocalStorage = (name, defaultValue) => {
  if ('undefined' !== typeof window) {
    try {
      const maybeValue = decodeValue(window.localStorage?.getItem(name));
      return maybeValue !== undefined && null !== maybeValue ? maybeValue : defaultValue;
    } catch (e) {
      return defaultValue;
    }
  }
  return defaultValue;
};

export const removeLocalStorage = name => {
  // eslint-disable-next-line no-unused-expressions
  'undefined' !== typeof window && window.localStorage?.removeItem(name);
};
