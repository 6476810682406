import { VStack } from '@chakra-ui/react';
import { FieldTitle, FileUploader } from '@pluxee-design-system/core';
import affiliationApi from 'api/affiliationApi';
import PreviewFile from 'components/Form/PreviewFile';
import { useField } from 'formik';
import { FileDetailResponse, FileType } from 'generated/models';
import useTranslations from 'i18n';
import React, { useCallback, useMemo, useState } from 'react';
import { ErrorCodeEnum, UploadStatusEnum } from './types';
import { getErrorCode } from './utils';

const mimeTypeAllowed = {
  'image/png': ['.png'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'application/pdf': ['.pdf'],
};

const maxOctetSize = 5000000; // ~ 5MB

interface UploadPoaProps {
  id?: string;
  mandatory?: boolean;
  name: string;
  title: string;

  onUpload?: (filePath: string) => void;
  onSuccess: (response: FileDetailResponse, filePath: string) => void;
  onReset: () => void;
  uploadedFilename?: string;
}

const UploadPoa = ({
  id,
  mandatory = false,
  name,
  title,

  onSuccess,
  onUpload,
  onReset,
  uploadedFilename = '',
}: UploadPoaProps) => {
  const [_field, meta] = useField<Array<string>>(name);
  const { t } = useTranslations();
  const [files, setFiles] = useState<File[]>([]);
  const [fileNames, setFileNames] = useState(() => (uploadedFilename ? [uploadedFilename] : []));
  const [status, setStatus] = useState(UploadStatusEnum.Init);
  const [errorCode, setErrorCode] = useState(ErrorCodeEnum.None);
  const hasError = Boolean(meta.error);
  const isTouched = Boolean(meta.touched || meta.value !== meta.initialValue);

  const errorMessage = useMemo(() => {
    switch (errorCode) {
      case ErrorCodeEnum.None:
        return undefined;
      case ErrorCodeEnum.Size:
        return t(
          'contract_confirmation.statusMessages.fileSizeError',
          'Your file size is not valid. Only files with size under 3MB are allowed.',
        );
      case ErrorCodeEnum.MimeType:
        return t(
          'contract_confirmation.statusMessages.fileFormatError',
          'Your file format is not valid. Only PDF, JPG and PNG files are allowed.',
        );
      case ErrorCodeEnum.Corrupted:
        return t(
          'contract_confirmation.statusMessages.fileCorruptedError',
          'Unfortunately, the uploaded file is too large or damaged. Please edit the given file or select another one.',
        );
      default:
        return t(
          'contract_confirmation.statusMessages.generalError',
          'An error occurred, please try again.',
        );
    }
  }, [errorCode, t]);

  const resetUploadState = useCallback(() => {
    setStatus(UploadStatusEnum.Init);
    setErrorCode(ErrorCodeEnum.None);
  }, [setStatus]);

  const handleFileDelete = useCallback(async () => {
    setFileNames([]);
    setFiles([]);
    onReset();
  }, [setFileNames, setFiles, onReset]);

  const handleFileDropReject = useCallback(
    (rejected: boolean) => {
      if (rejected) {
        setStatus(UploadStatusEnum.Failed);
        setErrorCode(ErrorCodeEnum.MimeType);
      }
    },
    [setErrorCode, setStatus],
  );

  const handleUploadFile = useCallback(
    async (acceptedFiles: File[]) => {
      try {
        if (acceptedFiles.length > 0) {
          resetUploadState();
          const existingFileNames = new Set<string>(fileNames);
          // upload only one file
          const filesToUpload = acceptedFiles.filter((file) => !existingFileNames.has(file.name));
          const file = filesToUpload?.[0];

          if (file) {
            if (file.size > maxOctetSize) {
              setStatus(UploadStatusEnum.Failed);
              setErrorCode(ErrorCodeEnum.Size);
            } else {
              setStatus(UploadStatusEnum.Uploading);
              onUpload?.(file.name);
              affiliationApi
                .affiliationFileUpload(FileType.Poa, file)
                .then((response) => {
                  setFileNames([file.name]);
                  setFiles([file]);
                  setStatus(UploadStatusEnum.Succeed);
                  onSuccess(response?.data, file.name);
                })
                .catch((error) => {
                  setStatus(UploadStatusEnum.Failed);
                  setErrorCode(getErrorCode(error));
                  onReset();
                });
            }
          }
        }
      } catch (e) {
        setStatus(UploadStatusEnum.Failed);
        setErrorCode(ErrorCodeEnum.Generic);
      }
    },
    [
      fileNames,
      onReset,
      onSuccess,
      onUpload,
      resetUploadState,
      setErrorCode,
      setFileNames,
      setFiles,
      setStatus,
    ],
  );

  return (
    <VStack align="stretch" spacing={4}>
      <FieldTitle title={title} mandatory={mandatory} />
      <FileUploader
        accept={mimeTypeAllowed}
        buttonText={t('contract_confirmation.form.powerOfAttorney.uploadButton', 'Upload PDF')}
        description={t('contract_confirmation.form.powerOfAttorney.uploadDrag', 'or drag a file')}
        files={fileNames}
        helpText={
          errorCode !== ErrorCodeEnum.None
            ? errorMessage
            : isTouched && hasError
              ? meta.error
              : t(
                  'contract_confirmation.form.powerOfAttorney.uploadRestriction',
                  'Only JPEG, PNG and PDF. Max a file size is 3 MB.',
                )
        }
        id={id}
        isLoading={status === UploadStatusEnum.Uploading}
        onFileDelete={handleFileDelete}
        onFileDrop={handleFileDropReject}
        onFileUpload={handleUploadFile}
        variant={errorCode !== ErrorCodeEnum.None || (isTouched && hasError) ? 'error' : undefined}
      />
      {files.map((f) => (
        <PreviewFile key={f.name} file={f} width={250} />
      ))}
    </VStack>
  );
};

export default UploadPoa;
