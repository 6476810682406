import { Box } from '@chakra-ui/react';
import type { PDFDocumentLoadingTask, PDFPageProxy } from 'pdfjs-dist';
import type { RenderParameters } from 'pdfjs-dist/types/src/display/api';
import { useEffect, useRef, useState } from 'react';
import { PreviewFileProps } from './types';
import { usePDFJS } from './utils';

const PreviewPdfFile = ({ file, width = 200 }: PreviewFileProps) => {
  const pdfjs = usePDFJS();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [page, setPage] = useState<PDFPageProxy | null>(null);

  useEffect(() => {
    if (!file || !pdfjs) return;
    let loadingPromise: Promise<void>;
    let loadingTask: PDFDocumentLoadingTask;

    file.arrayBuffer().then((arrayBuffer) => {
      const uint8Array = new Uint8Array(arrayBuffer);

      loadingTask = pdfjs.getDocument(uint8Array);
      loadingPromise = loadingTask.promise
        .then((pdf) => {
          pdf
            .getPage(1)
            .then(setPage)
            .catch((error) => console.log('rejected pdf page', error));
        })
        .catch((error) => {
          if (loadingTask.destroyed) return;
          console.log('rejected pdf', error);
        });
    });
    return () => {
      loadingPromise?.finally(() => loadingTask?.destroy());
    };
  }, [file, pdfjs]);

  useEffect(() => {
    if (!page) return;

    const canvas = canvasRef.current;
    if (!canvas) return;

    const viewport = page.getViewport({
      scale: 1,
    });
    const scaleFactor = width / viewport.width;

    const renderViewport = page.getViewport({
      scale: scaleFactor,
    });
    canvas.width = viewport.width;
    canvas.height = viewport.height;

    canvas.style.width = `${Math.floor(renderViewport.width)}px`;
    canvas.style.height = `${Math.floor(renderViewport.height)}px`;
    canvas.style.visibility = 'hidden';

    const renderContext: RenderParameters = {
      canvasContext: canvas.getContext('2d', { alpha: false }) as CanvasRenderingContext2D,
      viewport: viewport,
    };
    const cancellable = page.render(renderContext);
    const runningTask = cancellable;

    cancellable.promise
      .then(() => {
        canvas.style.visibility = '';
      })
      .catch((error) => {
        console.log(error);
      });

    return () => {
      runningTask.cancel?.();
    };
  }, [page, width]);

  return page ? (
    <Box p={4} alignSelf="flex-start" background="semantic.background.info">
      <canvas ref={canvasRef} />
    </Box>
  ) : null;
};

export default PreviewPdfFile;
