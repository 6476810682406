import { Box, Text, VStack } from '@chakra-ui/react';
import VideoButton from 'common/buttons/VideoButton';
import { CompanyIdControl, SubmitButton } from 'common/forms';
import { SplitScreen } from 'common/layouts';
import { TextTitle } from 'common/typography';
import CampaignLogo from 'components/Affiliation/CampaignLogo';
import { WizardStep, WizardStepProps } from 'components/WizardForm/types';
import { companyIdAsync, createSchema, getValidationErrors, MAGIC_ICO_BE } from 'config';
import { useFormikContext } from 'formik';
import useTranslations from 'i18n';
import { debounce } from 'lodash';
import { useCallback, useEffect } from 'react';
import gtm from 'trackers/gtm';
import { PageVideoEnum } from 'types/videos';
import maskString from 'utils/maskString';
import scrollToTop from 'utils/scrollToTop';
import { PrefillStatusEnum } from '../prefillStatus';
import { AffiliationValues, ExtraWizardProps } from '../types';

const trackCompanyId = debounce(
  (companyId?: string) => companyId && gtm.setVariables({ site_company_id: companyId.trim() }),
  500,
);

export const CompanyIdStep: WizardStep<WizardStepProps, ExtraWizardProps, AffiliationValues> = ({
  campaign,
  config: {
    companyId: { form: config },
  },
  isSubmitClicked,
}) => {
  const { t } = useTranslations();
  const { values } = useFormikContext<AffiliationValues>();
  const trackSubmit = useCallback(() => {
    if (isSubmitClicked) {
      isSubmitClicked.current = true;
    }
    scrollToTop();
  }, [isSubmitClicked]);

  useEffect(() => {
    trackCompanyId(values.companyId);
  }, [values.companyId]);

  return (
    <SplitScreen>
      {campaign && <CampaignLogo campaign={campaign} />}
      <VideoButton page={PageVideoEnum.AffiliationCompanyId} />
      <TextTitle
        mb={6}
        title={t('company_id.pageHeader', 'We made affiliation super easy!')}
        subtitle={t(
          'company_id.subheader',
          'More than 450 000 affiliated already joined us, and you?',
        )}
      />
      <VStack align="stretch" spacing={10}>
        <Box>
          <Text as="h3" variant="title.desktopCard">
            {t('company_id.sectionTexts.mainTitle', 'What is your Identification Number?')}
          </Text>
          <Text variant="subtitle.card" color="semantic.text.tertiary">
            {t(
              'company_id.sectionTexts.description',
              'We need this information to identify your company and prefill your information.',
            )}
          </Text>
        </Box>
        <CompanyIdControl
          mandatory
          name="companyId"
          id="CompanyIdStep_companyId"
          placeholder={t('company_id.form.companyId.placeholder', '12345678')}
          title={t('company_id.form.companyId.label', 'Your company ID')}
          format={config.companyId.format}
        />
        <SubmitButton
          variant="primaryFilled"
          id="CompanyIdStep__validateButton"
          onClick={trackSubmit}
          width="100%"
        >
          {t('company_id.button.validate', 'Confirm')}
        </SubmitButton>
      </VStack>
    </SplitScreen>
  );
};

CompanyIdStep.initial = () => ({
  companyId: '',
  masterId: '',
});

CompanyIdStep.validate =
  ({ config, isSubmitClicked }) =>
  async (values) => {
    const errors: Record<string, any> = {};
    try {
      const syncSchema = createSchema(config.companyId.form);
      await syncSchema.validateSync(values);
    } catch (error) {
      getValidationErrors([error], errors);
    }

    if (isSubmitClicked?.current && errors.companyId?.props) {
      gtm.trackClickFindMe(errors.companyId?.props?.id, errors.companyId?.props?.defaultMessage);
      isSubmitClicked.current = false;
    }

    return errors;
  };

CompanyIdStep.validateSubmit = async (values, { callCache }) => {
  const errors: Record<string, any> = {};
  try {
    const asyncSchema = companyIdAsync(callCache, true);
    await asyncSchema.validate(values);
  } catch (error) {
    getValidationErrors([error], errors);
  }
  if (errors.companyId?.props) {
    gtm.trackClickFindMe(errors.companyId?.props?.id, errors.companyId?.props?.defaultMessage);
  } else {
    gtm.trackClickFindMe();
  }

  return errors;
};

CompanyIdStep.onSubmit = async (
  currentValues,
  actions,
  setNextStepProps,
  {
    callCache,
    config: {
      companyInfo: { form: config },
    },
    freshValues,
  },
) => {
  let values = currentValues;

  // freshValues contains empty initialVales enriched by a companyId stored in localStore
  // in case companyId is not same as stored, we've to clear it for next upcoming changes
  if (currentValues.companyId !== freshValues!.companyId) {
    freshValues!.companyId = '';
    values = freshValues!;
  }

  const companyId = currentValues.companyId;
  const response = callCache[companyId];

  const masterId = response?.masterId || '';
  const companyDetails = response?.entry;
  const prefillDataStatus = companyDetails
    ? PrefillStatusEnum.Prefilled
    : PrefillStatusEnum.NotPrefilled;

  if (companyDetails && prefillDataStatus === PrefillStatusEnum.Prefilled) {
    const companyInfo: Partial<AffiliationValues> = {
      companyId,
      masterId,
      companyName: companyDetails.companyName || '',
      commercialName: values.commercialName
        ? values.commercialName
        : companyDetails.companyNameCommercial || companyDetails.companyName || '',
      streetName: companyDetails.street || companyDetails.city || '',
      houseNumber: companyDetails.houseNumber || '',
      city: companyDetails.city || '',
      box: companyDetails.box || '',
      postalCode: maskString(companyDetails.zip || '', config.postalCode.format),
      vatNumber: companyDetails.vatId || '',
      vatPayer: Boolean(companyDetails.vatId && companyDetails.vatId.length > 0),
      courtFileNumber: companyDetails.courtFileNumber || '',
      legalForm: companyDetails.legalForm || '',
      accountNumber: values.accountNumber ? values.accountNumber : companyDetails.accountIban || '',
    };
    const prefillAddressIncomplete = (
      ['streetName', 'houseNumber', 'city', 'postalCode'] as Array<keyof typeof config>
    ).some((f) => config[f].visible && config[f].mandatory && !companyInfo[f]);
    const allowedCompanyServices = companyDetails.benefitCategories ?? [];

    if (values.country === 'be' && prefillAddressIncomplete) {
      Object.assign(companyInfo, {
        streetName: values.streetName || companyInfo.streetName || '',
        houseNumber: values.houseNumber || companyInfo.houseNumber || '',
        city: values.city || companyInfo.city || '',
        box: values.box || companyInfo.box || '',
        postalCode: maskString(
          values.postalCode || companyInfo.postalCode || '',
          config.postalCode.format,
        ),
      });
    }

    setNextStepProps({ allowedCompanyServices, prefillDataStatus, prefillAddressIncomplete });
    // set Formik values for next step
    actions.setValues({ ...values, ...companyInfo }, false);
    return;
  } else {
    setNextStepProps({ prefillDataStatus });
  }

  // TODO: remove mock data
  if (companyId === MAGIC_ICO_BE) {
    const companyInfo: Partial<AffiliationValues> = {
      companyId,
      masterId,
      companyName: 'BELGI',
      commercialName: 'BELGI',
      streetName: 'Kozenstraat',
      houseNumber: '31',
      city: 'Hasselt',
      postalCode: maskString('3512', config.postalCode.format),
      legalForm: 'INPASS',
      // activity: [],

      // position: '41',
      // language: 'en',
      accountNumber: 'BE71096123456769',
      invoiceEmail: 'testan.testovicz@gmail.com',
      poReferenceNumber: '123REF',
      gdpr: true,
      termsAndConditions: true,
    };

    // set Formik values for next step
    actions.setValues({ ...values, ...companyInfo }, false);
    return;
  }

  // reset company step
  actions.setValues({ ...values, companyId, masterId });
};

export default CompanyIdStep;
