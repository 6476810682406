import { Box, HStack, SimpleGrid, Text } from '@chakra-ui/react';
import { Button } from '@pluxee-design-system/core';
import VideoButton from 'common/buttons/VideoButton';
import { SubmitButton } from 'common/forms';
import { OneColumn } from 'common/layouts';
import Loader from 'common/Loader';
import { TextTitle } from 'common/typography';
import CampaignLogo from 'components/Affiliation/CampaignLogo';
import {
  CommonProduct,
  ProductCard,
  ProductDrawer,
  SelectedProductOption,
} from 'components/Products';
import { getPriceAndFees } from 'components/Products/PayoutCard/utils';
import { getSelectedPayout } from 'components/Products/ProductDrawer/utils';
import { WizardStep, WizardStepProps } from 'components/WizardForm/types';
import { productsSchema } from 'config';
import useDeepMemo from 'data/useDeepMemo';
import { FieldArray, useFormikContext } from 'formik';
import { PayoutChannel, ProductFull } from 'generated/models';
import useTranslations from 'i18n';
import { intersection, isEqual } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import gtm from 'trackers/gtm';
import { PageVideoEnum } from 'types/videos';
import scrollToTop from 'utils/scrollToTop';
import Stepper from '../Stepper';
import { AffiliationValues, ExtraWizardProps } from '../types';
import SelectedPayoutInfo from './SelectedPayoutInfo';

export const ProductStep: WizardStep<WizardStepProps, ExtraWizardProps> = ({
  activeStep,
  campaign,
  toPrev: toPrevRaw,
  products: allProducts,
}) => {
  const { t, tQueryKey } = useTranslations();
  const { errors, initialValues, setFieldValue, touched, values } =
    useFormikContext<AffiliationValues>();
  const { activity, activityNames, partnerAccounts, products: basketItems } = values;
  const products = useMemo(
    () =>
      (allProducts || []).filter((product) => intersection(activity, product.services).length > 0),
    [activity, allProducts],
  );
  const isProductsTouched = useDeepMemo(
    () => Boolean(touched.products || !isEqual(initialValues.products, values.products)),
    [initialValues.products, touched.products, values.products],
  );

  // TODO: move to extra component
  const [drawerItem, setDrawerItem] = useState<ProductFull | undefined>(undefined);
  const toggleDrawerItem = useCallback(
    (item: ProductFull) => setDrawerItem((prev) => (prev === item ? undefined : item)),
    [setDrawerItem],
  );
  const resetDrawerItem = useCallback(() => setDrawerItem(undefined), [setDrawerItem]);
  const toPrev = useCallback(() => {
    gtm.trackClickProductSelectBack();
    toPrevRaw();
  }, [toPrevRaw]);
  const toNext = useCallback(() => {
    gtm.trackClickProductSelectConfirm(basketItems.length);
    scrollToTop();
  }, [basketItems.length]);

  if (!allProducts) {
    return <Loader />;
  }

  return (
    <>
      <OneColumn>
        <Box>
          {campaign && <CampaignLogo campaign={campaign} />}
          <VideoButton page={PageVideoEnum.AffiliationProduct} />
          <Stepper activeStep={activeStep} />
          <TextTitle
            mt={6}
            title={t('products.pageHeader', 'What products are the best match for your clients')}
          />
        </Box>
        <SimpleGrid templateColumns="repeat(auto-fit, 317px)" spacing={6}>
          {products.map((product) => {
            const basketItem = basketItems.find((item) => item.productUid === product.uid);
            const basketPayout = getSelectedPayout(product.payoutList, basketItem?.payoutUid);
            const isInBasket = Boolean(basketItem);
            const productName = t('product_details.products.*.product.name', product.name, {
              query: { where: 'product_details.products.*.product.code', is: product.nameKey },
            });
            const onShowProduct = () => {
              if (isInBasket) {
                gtm.trackClickProductEdit(productName);
              } else {
                gtm.trackClickProductSelect(
                  productName,
                  intersection(product.services, activity).map(
                    (activityId) => activityNames[activityId],
                  ),
                );
              }
              toggleDrawerItem(product);
            };

            return (
              <ProductCard
                key={product.uid}
                product={product}
                isSelected={product === drawerItem}
                isInBasket={isInBasket}
                onSelect={onShowProduct}
                withDescription={!isInBasket}
              >
                {basketPayout && <SelectedPayoutInfo payout={basketPayout} product={product} />}
              </ProductCard>
            );
          })}
        </SimpleGrid>
      </OneColumn>
      <Box position="sticky" bottom={0} margin={{ base: 0, md: '0 auto' }}>
        <HStack
          boxShadow="bottom"
          justifyContent="flex-end"
          spacing={6}
          pr={{ base: 6, lg: 36 }}
          pl={6}
          py={5}
          background="semantic.surface.1"
          minWidth={{ base: '100%', md: '750px' }}
        >
          {isProductsTouched && errors.products && (
            <Text variant="body.largeDemibold" color="semantic.text.error">
              {errors.products as string}
            </Text>
          )}
          <Button
            variant="primaryOutlined"
            id="ProductSelectionStep_backButton"
            type="button"
            onClick={toPrev}
            background="semantic.surface.1"
          >
            {t('global_texts.buttons.back', 'Back')}
          </Button>
          <SubmitButton
            variant="primaryFilled"
            id="ProductSelectionStep__validateButton"
            onClick={toNext}
          >
            {t('global_texts.buttons.confirm', 'Confirm')}
          </SubmitButton>
        </HStack>
      </Box>
      <FieldArray
        name="products"
        validateOnChange
        render={({ push, replace, remove: removeRaw }) => {
          if (!drawerItem) return null; // to let the field connect to Formik context

          const remove = (index: number) => {
            gtm.trackClickProductDelete(basketItems[index]?.productName);
            removeRaw(index);
          };

          return (
            <ProductDrawer
              isProductDetachable={basketItems.some((i) => i.productUid === drawerItem.uid)}
              product={drawerItem}
              partnerAccounts={partnerAccounts}
              removeText={t('products.sidebarButtons.remove', 'Remove product from selection')}
              selectedPayoutId={basketItems.find((i) => i.productUid === drawerItem.uid)?.payoutUid}
              onClose={resetDrawerItem}
              onRemove={(product: CommonProduct) => {
                const index = basketItems.findIndex((item) => item.productUid === product.uid);
                remove(index);
                resetDrawerItem();
              }}
              onSelect={(product: CommonProduct, payout: PayoutChannel, _, __, accountNumber) => {
                const index = basketItems.findIndex((item) => item.productUid === product.uid);
                const tQueryProduct = tQueryKey('product_details.products.*.product', {
                  where: 'product_details.products.*.product.code',
                  is: product.nameKey,
                });
                const tQueryTier = tQueryKey(`${tQueryProduct}.tiers.*.tier`, {
                  where: `${tQueryProduct}.tiers.*.tier.code`,
                  is: payout.priceTierName,
                });

                const productName = t(`${tQueryProduct}.name`, product.name);
                const contract: SelectedProductOption = {
                  productUid: product.uid,
                  payoutUid: payout.payoutUid ?? undefined,
                  // for tracking
                  productName,
                  productNameKey: product.nameKey,
                  // needed by backend
                  productCode: product.code,
                  payoutChannelCode: payout.code ?? undefined,
                  payoutFrequencyCode: payout.frequencyCode ?? undefined,
                  priceTierCode: payout.priceTierCode,
                  // partner account info
                  partnerKey: payout.parameterCode,
                };
                setFieldValue(
                  'partnerAccounts',
                  { ...partnerAccounts, [String(payout.parameterCode)]: accountNumber },
                  false,
                );

                gtm.trackClickProductOfferApply(
                  productName,
                  getPriceAndFees(payout.priceList),
                  payout.frequencyIndividual
                    ? t('product_details.payoutFrequency.individual', 'Individual')
                    : t(
                        `product_details.payoutFrequency.${payout.frequencyNameKey}`,
                        payout.frequencyName,
                      ),
                  t(`product_details.payouts.*.${payout.nameKey}.name`, payout.name),
                  t(`${tQueryTier}.name`, payout.priceTierName),
                );

                if (index >= 0) {
                  replace(index, contract);
                } else {
                  push(contract);
                }
                resetDrawerItem();
              }}
              withTracking
            />
          );
        }}
      />
    </>
  );
};

ProductStep.initial = () => ({
  products: [],
  partnerAccounts: {},
});

ProductStep.schema = () => productsSchema(true);

export default ProductStep;
