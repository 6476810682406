import { merge, pick } from 'lodash';
import { CompanyIdStep } from 'components/Affiliation/CompanyIdStep';
import { CompanyInfoStep } from 'components/Affiliation/CompanyInfoStep';
import { ContactInfoStep } from 'components/Affiliation/ContactInfoStep';
import { ProductStep } from 'components/Affiliation/ProductStep';
import { PaymentInfoStep } from 'components/Affiliation/PaymentInfoStep';
import { ContractConfirmationStep } from 'components/Affiliation/ContractConfirmationStep';
import { CountryCode } from 'types/config';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'utils/localStorageManager';
import { AffiliationStepEnum, SessionUserType } from 'types/common';
import { AffiliationValues } from './types';

type StepType = Record<AffiliationStepEnum, Record<string, unknown>>;

export const STEPS: StepType = {
  [AffiliationStepEnum.CompanyId]: CompanyIdStep.initial(),
  [AffiliationStepEnum.CompanyInfo]: CompanyInfoStep.initial(),
  [AffiliationStepEnum.ContactInfo]: ContactInfoStep.initial(),
  [AffiliationStepEnum.ProductSelection]: ProductStep.initial(),
  [AffiliationStepEnum.PaymentInfo]: PaymentInfoStep.initial(),
  [AffiliationStepEnum.ContractConfirmation]: ContractConfirmationStep.initial(),
};

const getKey = (id: string | undefined) => `affiliation-${id}`;

// TODO: what about name collision? flatten
const getAffiliationValues = (stepValues: Partial<StepType>) =>
  Object.values(stepValues).reduce((result, currentValue) => {
    result = merge(result, currentValue);
    return result;
  }, {} as AffiliationValues);

export const discardAffiliation = (id: string) => {
  removeLocalStorage(getKey(id));
};

export const storeAffiliation = (id: string | undefined, changes: AffiliationValues) => {
  setLocalStorage(getKey(id), changes);
};

/**
 * Calculates initial affiliation values
 *
 * @param isoCode Current country
 * @param currentUser Currently logged-in user
 * @param sessionKey Identifier used to retrieve data from local storage
 */
export const getInitialAffiliationValues = (
  isoCode: CountryCode,
  currentUser?: SessionUserType,
  sessionKey?: string,
) => {
  const initialValues: Partial<StepType> = {};
  const sessionValues: Partial<StepType> = {};
  const maybeValues = getLocalStorage(getKey(sessionKey), {});

  Object.values(AffiliationStepEnum).forEach((stepName) => {
    const stepValues = STEPS[stepName];
    const picked = pick(maybeValues, Object.keys(stepValues));
    initialValues[stepName] = merge({}, stepValues);
    sessionValues[stepName] = merge({}, stepValues, picked);
  });

  if (currentUser) {
    const contactInfoStep = AffiliationStepEnum.ContactInfo;
    const contactInfo = {
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      email: currentUser.email,
      phoneNumber: currentUser.telephone,
      signatoryPerson: true,
    };
    initialValues[contactInfoStep] = merge({}, initialValues[contactInfoStep], contactInfo);
    sessionValues[contactInfoStep] = merge({}, sessionValues[contactInfoStep], contactInfo);
  }

  const initValues = getAffiliationValues(initialValues);
  const storedValues = getAffiliationValues(sessionValues);
  initValues.companyId = storedValues.companyId;
  initValues.country = isoCode;
  storedValues.country = isoCode;

  return { initValues, storedValues };
};

export const hasModifiedAffiliation = (id: string) =>
  Boolean(getLocalStorage(getKey(id), null)?.companyId);
