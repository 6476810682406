import { Text } from '@chakra-ui/react';
import { SelectOption } from '@pluxee-design-system/core';
import BePayoutCard from 'components/Products/PayoutCard/BePayoutCard';
import CzPayoutCard from 'components/Products/PayoutCard/CzPayoutCard';
import useConfig from 'data/useConfig';
import {
  LocationTypeEnum,
  PayoutChannelSelected,
  Product,
  ProductFull,
  ProductStatusEnum,
} from 'generated/models';
import useTranslations from 'i18n';
import { memo } from 'react';

export interface ProductCheckboxItem extends SelectOption {
  isAllowed?: boolean;
  locationType?: LocationTypeEnum;
  payout?: PayoutChannelSelected | null;
  product: Product | ProductFull;
  status?: ProductStatusEnum;
}

const ProductCheckbox = ({ locationType, payout, product }: ProductCheckboxItem) => {
  const { isoCode } = useConfig();
  const { t } = useTranslations();
  const PayoutCardComponent = isoCode === 'be' ? BePayoutCard : CzPayoutCard;

  if (!payout || isoCode === 'cz') {
    return (
      <Text variant="body.largeDemibold">
        {t('product_details.products.*.product.name', product.name, {
          query: { where: 'product_details.products.*.product.code', is: product.nameKey },
        })}
      </Text>
    );
  }

  return (
    <PayoutCardComponent
      channel={payout}
      locationType={locationType}
      productName={product.name}
      productNameKey={product.nameKey}
      onlyProductName
    />
  );
};

export default memo(ProductCheckbox);
