import { useCompanyServices } from 'api/companyApi';
import { useLegalForms } from 'api/defaultApi';
import SelectControl, { SelectControlProps } from 'common/forms/SelectControl';
import ServiceList, { ServiceListProps } from 'components/Form/ServiceList';
import {
  ContractService,
  LegalForm,
  LegalFormListResponse,
  ServicesResponse,
} from 'generated/models';
import withData from 'HOC/withData';

export const CompanyServiceSelect = withData<ServicesResponse, ContractService[], any, Omit<ServiceListProps, 'options'>>(ServiceList, {
  namespace: 'company_services',
  propsKey: 'options',
  selector: (response, t, tHtml, isoCode) =>
    response.entries.map((service) => ({
      value: service.code,
      label:
        isoCode === 'cz'
          ? t(`company_services.companyServices.*.${service.nameKey}.name`, service.name)
          : t(`company_services.companyServices.${service.nameKey}`, service.name),
      description:
        isoCode === 'cz'
          ? tHtml(
        `company_services.companyServices.*.${service.nameKey}.description`,
        service.name,
      )
          : t(`company_services.companyServices.${service.nameKey}`, service.name),
    })),
  defaultData: [],
  useFetcher: useCompanyServices,
});

export const LegalFormSelectControl = withData<
  LegalFormListResponse,
  LegalForm[],
  any,
  Omit<SelectControlProps, 'options'>
>(SelectControl, {
  namespace: 'legal_forms',
  propsKey: 'options',
  selector: (response, t) =>
    response.entries.map(({ code, name, nameKey }) => ({
      value: code,
      label: t(`legal_forms.legalForm.${nameKey}`, name),
    })),
  defaultData: [],
  useFetcher: useLegalForms,
});
