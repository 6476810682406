import type * as PDFJS from 'pdfjs-dist';
import { useEffect, useState } from 'react';

export const usePDFJS = (onLoad?: (pdfjs: typeof PDFJS) => Promise<void>) => {
  const [pdfjs, setPDFJS] = useState<typeof PDFJS | null>(null);

  // load the library once on mount (the webpack import automatically sets-up the worker)
  useEffect(() => {
    // @ts-expect-error
    import('pdfjs-dist/webpack.mjs').then(setPDFJS);
  }, []);

  // execute the callback function whenever PDFJS loads (or a custom dependency-array updates)
  useEffect(() => {
    if (!pdfjs) return;
    if (onLoad) (async () => await onLoad(pdfjs))();
  }, [onLoad, pdfjs]);

  return pdfjs;
};
