import PreviewImgFile from './PreviewImgFile';
import PreviewPdfFile from './PreviewPdfFile';
import { PreviewFileProps } from './types';

const PreviewFile = (props: PreviewFileProps) => {
  const { file } = props;
  switch (file.type) {
    case 'application/pdf':
      return <PreviewPdfFile {...props} />;
    case 'image/jpeg':
    case 'image/png':
      return <PreviewImgFile {...props} />;
    default:
      return null;
  }
};

export default PreviewFile;
